<template>
    <div>
      <el-dialog :title="title" :visible.sync="dia.show" width="60%">
        <div class="diaHead" style="color: red;">
            温馨提示：带*号为必填项
        </div>
        <div class="input" style="margin-bottom: 30px;">

          <div class="input_info">
            <p class="input_text"><span style="color: red;">*</span>门店名称：</p>
            <el-input v-model="formData.store_name" placeholder="请输入店铺名称" size="middle" class="input_select" clearable  v-if="is_editable === 0" disabled="true" />
            <el-input v-model="formData.store_name" placeholder="请输入店铺名称" size="middle" class="input_select" clearable  v-else />
          </div>
          <div class="input_info">
            <p class="input_text">门店名称<span style="color: red;">（优秀作品展示此项）</span>：</p>
            <el-input v-model="formData.supervisor_store_name" placeholder="请输入店铺名称" size="middle" class="input_select" clearable />
          </div>
  
          <div class="input_info">
            <p class="input_text"><span style="color: red;">*</span>开业日期：</p>
            <el-date-picker
                v-model="formData.opening_date"
                type="date"
                value-format="yyyy-MM-dd"
                class="input_select"
                placeholder="请选择开业日期" clearable v-if="is_editable === 0" disabled="true">
            </el-date-picker>
            <el-date-picker
                v-model="formData.opening_date"
                type="date"
                value-format="yyyy-MM-dd"
                class="input_select"
                placeholder="请选择开业日期" clearable v-else>
            </el-date-picker>
          </div>

          <div class="input_info">
            <p class="input_text">开业日期<span style="color: red;">（优秀作品展示此项）</span>：</p>
            <el-date-picker
                v-model="formData.supervisor_opening_date"
                type="date"
                value-format="yyyy-MM-dd"
                class="input_select"
                placeholder="请选择开业日期" >
            </el-date-picker>
          </div>

            
          <div class="input_info">
            <p class="input_text"><span style="color: red;">*</span>地址：</p>
            <el-input v-model="formData.address" placeholder="请输入地址" type="textarea" size="middle" class="input_select" clearable v-if="is_editable === 0" disabled="true" />
            <el-input v-model="formData.address" placeholder="请输入地址" type="textarea" size="middle" class="input_select" clearable v-else />
          </div>

          <div class="input_info">
            <p class="input_text">地址<span style="color: red;">（优秀作品展示此项）</span>：</p>
            <el-input v-model="formData.supervisor_address" placeholder="请输入地址" type="textarea" size="middle" class="input_select" clearable />
          </div>

          <div class="input_info">
            <p class="input_text"><span style="color: red;">*</span>所属督导：</p>
            <el-input v-model="formData.supervisor" placeholder="请输入所属督导" size="middle" class="input_select" clearable v-if="is_editable === 0" disabled="true" />
            <el-input v-model="formData.supervisor" placeholder="请输入所属督导" size="middle" class="input_select" clearable v-else />
          </div>

          <div class="input_info">
            <p class="input_text">是否优秀：</p>
            <el-radio v-model="formData.is_as_good_show" :label="1">是</el-radio>
            <el-radio v-model="formData.is_as_good_show" :label="0">否</el-radio>
          </div>

          <div class="input_info">
            <p class="input_text">排序<span style="color: red;">（优秀作品展示按从低到高排序）</span>：</p>
            <el-input v-model="formData.sort_key" placeholder="" type="number" size="middle" class="input_select" clearable />
          </div>

          <div class="input_info" style="width: 100%">
            <p class="input_text">门店陈列照片：</p>
            <ul>
              <li v-for="(item,index) in formData.ImageArr" :key="index" style="display: flex;">
                <el-link  style="text-align: left;" @click="handlePreview(item.img_url)" >
                  <el-image style="width: 50px; height: 50px;margin: 5px;" :src="item.img_url" alt=""></el-image>
                </el-link>
                <el-checkbox v-model="item.is_as_good_show" :true-label="1" :false-label="0" style="margin: auto;padding-left: 18px;">优秀</el-checkbox>
                <template v-if="is_editable === 1" >
                  <i class="el-icon-delete" style="margin: auto;padding-left: 20px;font-size: 18px;cursor: pointer;color: red;" @click="handleImgBeforeRemove(item.img_url,index+1)"></i>
                </template>
              </li>
            </ul>
              <!--            图片放大预览-->
              <el-dialog :visible.sync="dialogVisible" append-to-body>
                  <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
          </div>

          <!-- 视频 -->
          <div class="input_info" style="width: 100%">
            <p class="input_text">门店陈列视频：</p>
              <ul>
                <li v-for="(item,index) in formData.VideoArr" :key="index" style="display: flex;">
                  <el-link  style="text-align: left;" @click="handleVideoPreview(item.video_url)" >
                    {{ item.video_url.split('/')[item.video_url.split('/').length-1] }}
                  </el-link>
                  <template v-if="is_editable === 1" >
                      <i class="el-icon-delete" style="margin: auto;padding-left: 20px;font-size: 18px;cursor: pointer;color: red;" @click="handleVideoBeforeRemove(item.video_url,index+1)"></i>
                  </template>
                </li>
              </ul>
              <!--            图片放大预览-->
              <el-dialog :visible.sync="dialogVideoVisible" append-to-body>
                    <video width="100%" height="100%" controls>
                      <source :src="dialogVideoUrl" type="video/mp4" />
                      您的浏览器不支持 video 标签。
                    </video>
                </el-dialog>
          </div>
  

  
        </div>
        <div class="diaFooter">
          <el-button size="mini" @click="dia.show = false">取 消</el-button>
          <el-button size="mini" type="primary" @click="submitForm">提 交</el-button>
        </div>
      </el-dialog>
    </div>
  </template>
  
  <script>
import displayCompetitionApi from "@/api/admin/displayCompetition";
  
  export default {
    name: 'Edit',
    data() {
      return {
        title: '编辑陈列竞赛',
        dia: {
          show: false
        },
        is_editable:1,//是否可编辑
        //   表单
        formData:{
            id:0,
            store_name:"",
            supervisor:"",
            opening_date:"",
            address:"",
            ImageArr:[],
            VideoArr:[],
            supervisor_store_name:"",
            supervisor_opening_date:"",
            supervisor_address:"",
            is_as_good_show:0,
            sort_key:0
        },
        BigImageArr:[],//预览图片
        pageInfo: {
            page: 1,
            pagesize: 10,
            total: 0,
        },
        tableData:[],
        // 预览图片
        dialogImageUrl: '',
        dialogVisible: false,
        // 预览视频
        dialogVideoUrl: '',
        dialogVideoVisible:false,
        disabled: false,
      }
    },
    watch: {
      'dia.show'() {
        if (this.dia.show === false) {
          // 清空数据
          this.resetFormData()
        }
      }
    },
    created() {
  
    },
    methods: {

     /* 重置数据 */
     resetFormData(){
        this.formData = {
            id:0,
            store_name:"",
            supervisor:"",
            opening_date:"",
            address:"",
            ImageArr:[],
            VideoArr:[],
            supervisor_store_name:"",
            supervisor_opening_date:"",
            supervisor_address:"",
            is_as_good_show:0,
            sort_key:0
        }
        this.is_editable = 1
     },
  
      // 关闭
      closeDiv() {
        this.dia.show = false
      },
  
      /* 编辑 */
      EditFunc(row) {
        this.dia.show = true
        this.initEditData(row)
      },
  
      // 编辑初始化数据
      initEditData(row) {
        this.resetFormData() // 清空数据
        this.dia.show = true
  
        // 图片视频赋值
        const data = row
        // 初始赋值
        this.formData.id = data.id
        this.formData.store_name = data.store_name
        this.formData.supervisor = data.supervisor
        this.formData.opening_date = (data.opening_date? new Date(data.opening_date * 1000).toLocaleString("zh-Hans-CN",{year:'numeric',month:'2-digit',day:'2-digit'}).replace(/\//g,'-') :"")
        this.formData.address = data.address
        this.formData.supervisor_store_name = data.supervisor_store_name
        this.formData.supervisor_opening_date = (data.supervisor_opening_date? new Date(data.supervisor_opening_date * 1000).toLocaleString("zh-Hans-CN",{year:'numeric',month:'2-digit',day:'2-digit'}).replace(/\//g,'-') :"")
        this.formData.supervisor_address = data.supervisor_address
        this.formData.is_as_good_show = data.is_as_good_show
        this.formData.sort_key = data.sort_key
        this.formData.ImageArr= data.ImageArr
        this.formData.VideoArr = data.VideoArr
        this.is_editable = data.is_editable
      },
  
      // 操作返回成功
      successAction(msg) {
        this.dia.show = false
        this.$emit('reload')
        this.$message.success(msg)
      },
      // submitForm 提交数据
      submitForm() {
        // 提交基础信息
        let postData = {
          id:0,
          store_name:'',
          supervisor:'',
          opening_date:'',
          address:'',
          ImageArr:[],
          supervisor_store_name:'',
          supervisor_opening_date:'',
          supervisor_address:'',
          is_as_good_show:0,
        }
        postData.id = this.formData.id
        postData.store_name = this.formData.store_name
        postData.supervisor = this.formData.supervisor
        postData.opening_date = this.formData.opening_date
        postData.address = this.formData.address
        postData.supervisor_store_name = this.formData.supervisor_store_name
        postData.supervisor_opening_date = this.formData.supervisor_opening_date
        postData.supervisor_address = this.formData.supervisor_address
        postData.is_as_good_show = parseInt(this.formData.is_as_good_show)
        postData.sort_key = parseInt(this.formData.sort_key)
        if (this.formData.ImageArr.length>0) {
          for (const key in this.formData.ImageArr) {
            let temp = {
              id :this.formData.ImageArr[key].id,
              is_as_good_show:this.formData.ImageArr[key].is_as_good_show?parseInt(this.formData.ImageArr[key].is_as_good_show):0,
            }
            postData.ImageArr.push(temp)
          }
        }
        if (this.formData.id) {
          // 编辑
          displayCompetitionApi.edit(postData).then((res) => {
            const { error } = res.data
            if (error === 0) {
              this.$message.success('编辑成功')
            } else {
              this.$message.error('编辑失败')
            }
            this.$emit('reload')
            this.dia.show = false
            this.resetFormData()
          })
        } else {
            this.$message.error('数据出错，请刷新界面或者联系技术')
        }
      },
      


      // ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓图片····相关操作↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓/
  
    // 预览图片
    handlePreview(url) {
        this.dialogImageUrl=url;
        this.dialogVisible=true;
    },
  
    /**
     * 删除图片
     */
    handleDeleteImg(postData) {
      const loading = this.$loading({
        lock: true,
        text: '正在删除，请稍候',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      displayCompetitionApi.deleteImg(postData).then((res) => {
          const error  = res.data.error
          if (error === 0) {
          this.$message({
              type: 'success',
              message: 'Successfully deleted!'
          })
          } else {
          this.$message({
              type: 'info',
              message: 'undelete'
          })
          }
      }).finally(()=>{
        loading.close()
      })
    },

    /**
     * 删除图片之前确认
     */
    handleImgBeforeRemove(url,index) {
      let tipText = "此操作将永久删除该文件, 是否继续?"
        this.$confirm(tipText, '删除照片提示：第'+index+'张照片', {
          confirmButtonText: 'confirm',
          cancelButtonText: 'cancel',
          type: 'warning'
        })
          .then((res) => {
            // 移除展示的数组
            for (let index = 0; index < this.formData.ImageArr.length; index++) {
              if (this.formData.ImageArr[index].img_url === url) {
                this.formData.ImageArr.splice(index, 1) // 移除展示的图片
              }
            }
            // 删除图片
            var delArr = []
            delArr.push({imgSrc:url})
            this.handleDeleteImg({img_arr: delArr})
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: 'undelete'
            })
          })
    }, 
    // ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑图片····相关操作↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑/


    // ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓视频···········相关操作↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓/
  
    // 预览视频
    handleVideoPreview(url) {
        this.dialogVideoUrl=url;
        this.dialogVideoVisible=true;
    },
  
    /**
     * 删除视频
     */
    handleDeleteVideo(postData) {
      const loading = this.$loading({
        lock: true,
        text: '正在删除，请稍候',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      displayCompetitionApi.deleteVideo(postData).then((res) => {
          const error  = res.data.error
          if (error === 0) {
          this.$message({
              type: 'success',
              message: 'Successfully deleted!'
          })
          } else {
          this.$message({
              type: 'info',
              message: 'undelete'
          })
          }
      }).finally(()=>{
        loading.close()
      })
    },

    /**
     * 删除视频之前确认
     */
    handleVideoBeforeRemove(url,index) {
      let tipText = "此操作将永久删除该文件, 是否继续?"
        this.$confirm(tipText, '删除视频提示：第'+index+'个视频', {
          confirmButtonText: 'confirm',
          cancelButtonText: 'cancel',
          type: 'warning'
        })
          .then((res) => {
            // 移除展示的数组
            for (let index = 0; index < this.formData.VideoArr.length; index++) {
              if (this.formData.VideoArr[index].video_url === url) {
                this.formData.VideoArr.splice(index, 1) // 移除展示的视频
              }
            }
            // 删除视频
            var delArr = []
            delArr.push({videoSrc:url})
            this.handleDeleteVideo({video_arr: delArr})
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: 'undelete'
            })
          })
    }  
    // ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑视频···········相关操作↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑/
  
    }
  }
  </script>
  
        <style lang="scss" scoped>
          @import "@/views/admin/home/displayCompetition/css/edit.scss";
          </style>