<template>
    <div class="banner">
      <div class="banner-header">
        <el-button type="primary" v-show="showDownBtn" @click="handleExport">打包导出</el-button>
      </div>
      <div class="banner-content">
        <div class="banner-content-list">
          <el-table :data="tableData" @selection-change="handleSelectionChange">
            <el-table-column type="selection" align="center" width="55">
            </el-table-column>
            <el-table-column prop="id" label="系统自增id" width="80" align="center"></el-table-column>
            <el-table-column prop=""  width="130" label="客户信息">
              <template v-slot="scope">
                客户：{{ scope.row.UserInfo.username }}<br>海鼎编码：{{ scope.row.UserInfo.hd_code }}
              </template>
            </el-table-column>
            <el-table-column prop="store_name"  width="90" label="门店名称"></el-table-column>
            <el-table-column prop="supervisor" width="80" label="所属督导"></el-table-column>
            <el-table-column prop="opening_date" width="95" label="开业日期">
              <template slot-scope="scope">
                {{scope.row.opening_date? new Date(scope.row.opening_date * 1000).toLocaleString("zh-Hans-CN",{year:'numeric',month:'2-digit',day:'2-digit'}).replace(/\//g,'-'): ''}}
              </template>
            </el-table-column>
            <el-table-column prop="address" label="地址" width="200"></el-table-column>
            <el-table-column label="陈列照片（点击查看大图）">
              <template v-slot="scope">
                <el-image style="width: 50px; height: 50px;margin: 5px;" :src="item.img_url" v-for="(item,index) in scope.row.ImageArr" :key="index" alt="" :preview-src-list="scope.row.BigImageArr"></el-image>
              </template>
            </el-table-column>
            <el-table-column label="视频（点击播放）">
              <template v-slot="scope">
                <el-link  v-for="(item,index) in scope.row.VideoArr" :key="index" style="text-align: left;" @click="tableDataVideoPreview(item.video_url)" >
                  {{ item.video_url.split('/')[item.video_url.split('/').length-1] }}
                </el-link><br/>
              </template>
            </el-table-column>
            <el-table-column label="提交时间" width="90">
              <template slot-scope="{row}">
                {{ new Date(new Date(row.created_at).getTime()).toLocaleString("zh-Hans-CN",{year:'numeric',month:'2-digit',day:'2-digit',hour:'2-digit', minute:'2-digit', second:'2-digit'}).replace(/\//g,'-') }}
              </template>
            </el-table-column>
            <el-table-column label="是否优秀" width="80">
              <template v-slot="scope">
                <span v-if="scope.row.is_as_good_show==1" style="color: red;">优秀</span>  
              </template>
            </el-table-column>
            <el-table-column label="优秀排序（1为最优秀）" prop="sort_key" width="80"></el-table-column>
            <el-table-column label="操作" width="180">
              <template slot-scope="{ row,$index}" >
                <el-button v-show="showDownBtn" type="primary" plain @click="handleEdit(row)" size="mini">编辑</el-button>
                <el-popover
                  trigger="click"
                  placement="top"
                >
                  <p>你确认要删除这条数据吗? </p>
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="text" @click="hidePopover()">取消</el-button>
                    <el-button type="primary" size="mini" @click="handleDelete($index,tableData)">确认</el-button>
                  </div>
                  <el-button v-show="showDownBtn" slot="reference" type="danger" size="mini" style="margin:0 10px" @click.stop>删除</el-button>
                </el-popover>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!--            图片放大预览-->
        <el-dialog :visible.sync="dialogVideoVisible">
            <video width="100%" height="100%" controls>
              <source :src="dialogVideoUrl" type="video/mp4" />
              您的浏览器不支持 video 标签。
            </video>
        </el-dialog>
        <div ref="footer" class="banner-footer" v-if="tableData.length > 0">
          <el-pagination
            :key="1"
            :current-page="pageInfo.page"
            :page-size="pageInfo.pagesize"
            :page-sizes="[10, 20, 50, 100, 200]"
            layout="total,sizes, prev, pager, next, jumper"
            :total="pageInfo.total"
            @size-change="handleRenewRenewSizeChange"
            @current-change="handleChangeCurrentPage"
            background
          />
        </div>

        <Edit ref="Edit" @reload="getTableData" />

      </div>
    </div>
  </template>
  
  <script>
  import displayCompetition from "./js/index";
  
  export default displayCompetition;
  </script>
  
  <style scoped lang="scss">
  @import "css/index";
  </style>
  
  <style>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  </style>
  