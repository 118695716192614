import axios from "axios";

export default {
  get: (page, pagesize) => {
    const params = {
      page: page,
      pagesize: pagesize,
    };
    return axios.get("/a1/displaycompetition/getList", { params: params });
  },

  deleteImg:(params)=>{
    return axios.put("/a1/displaycompetition/deleteDCImg", params);
  },

  deleteVideo:(params)=>{
    return axios.put("/a1/displaycompetition/deleteDCVideo", params);
  },


  edit: (params) => {
    return axios.put("/a1/displaycompetition/editDC", params);
  },
  del: (id) => {
    return axios.delete("/a1/displaycompetition/deleteDC/"+id);
  },

};
