import common from "@/common/mixins/common.js";
import displayCompetitionApi from "@/api/admin/displayCompetition";
import Edit from "@/views/admin/home/displayCompetition/components/edit"

export default {
  name: "displayCompetition",
  components: {
    Edit
  },
  mixins: [common],
  data() {
    return {
      loading: false, // 加载loading
      pageInfo: {
        page: 1,
        pagesize: 10,
        total: 0,
      },
      tableData: [],
      // 预览视频
      dialogVideoUrl: '',
      dialogVideoVisible:false,
      multipleSelection: [],
      showDownBtn:true,
    };
  },
  watch: {
    
    
  },
  created() {
    this.getTableData();
    this.checkUserGroup()
  },
  methods: {

    /* 编辑 */
    handleEdit(row) {
      this.$refs.Edit.EditFunc(row)
    },

    /* 删除单条数据 */
    handleDelete(index,tableData){
      const data = tableData[index]
      let tipText = "确认要删除此条数据？"
        this.$confirm(tipText, 'prompt', {
          confirmButtonText: 'confirm',
          cancelButtonText: 'cancel',
          type: 'warning'
        })
          .then((res) => {
            displayCompetitionApi.del(data.id).then((res) => {
              this.$message.success("删除成功！");
            }).catch((err) => {
              const data=err.response.data;
              this.$message.error(data.message);
            }).finally(()=>{
              this.getTableData()
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: 'undelete'
            })
          })
      
    },

    // 隐藏删除按钮
    hidePopover() {
      this.$refs.footer.click()
    },


    // 判断是否有督导，有权限查看下载按钮
    checkUserGroup(){
      let adminUser=JSON.parse(window.sessionStorage.getItem("user"));
      if (parseInt(adminUser.admin_info.group_id) !== 21) {
        this.showDownBtn = false
      }
    },
    //列表多选项框
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    
    /* 列表预览视频 */
    tableDataVideoPreview(url){
      this.dialogVideoUrl=url;
      this.dialogVideoVisible=true;
    },
    // 隐藏
    hideLoading(){
      this.loading = false
    },

    //改变条数
    handleRenewRenewSizeChange(val) {
      this.pageInfo.pagesize=val;
      this.getTableData();
    }, //翻页
    handleChangeCurrentPage(val) {
      this.pageInfo.page=val;
      this.getTableData();
    },

    // 请求数据
    getTableData() {
      displayCompetitionApi.get(this.pageInfo.page, this.pageInfo.pagesize, -1, -1).then((res) => {
        this.tableData=res.data.data.data_list?res.data.data.data_list:[];
        this.pageInfo.total=res.data.data.total;
        // 设置图片预览
        if (this.tableData.length >0) {
          for (const key in this.tableData) {
            if (typeof(this.tableData[key].BigImageArr) === "undefined") {
              this.tableData[key].BigImageArr = []
            }
            for (const key2 in this.tableData[key].ImageArr) {
              this.tableData[key].BigImageArr.push(this.tableData[key].ImageArr[key2].img_url)
            }
          }
        }
        // console.log(this.tableData)
      });
    },

    /* 打包导出数据 */
    async handleExport(){
      let postIds = []
      if (this.multipleSelection.length>0) {
        this.multipleSelection.forEach(item => {
          postIds.push(parseInt(item.id))
        });
        let postData = {
          "ids": postIds
        }
        const loading = this.$loading({
          lock: true,
          text: '正在打包，请稍后',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        await this.$axios.post("/a1/displaycompetition/createDownDisplayCompetition", postData).then((res) => {

          let dowloadKey = res.data.data.to_key;
          //开始下载
          location.href = this.$root.DownUrl + "/c1/down/" + dowloadKey;
        }).catch((err) => {
          this.$message.success(err.response.data.data)
        })
        loading.close()
      } else {
        this.$message.error("请选择需要下载的数据")
      }

    },

    

    
  },
};
